<template>



  <div class="xl:grid grid-cols-2 h-full w-full">
    <div class="h-full w-full">

      <yandex-map v-model="map" :settings="{
        location: {
          // center: [37.617348, 54.193122],
          ...LOCATION,
          // zoom: 13,
        },
        behaviors: enabledBehaviors
      }" width="100%" height="100%">

        <yandex-map-default-scheme-layer />
        <yandex-map-default-features-layer />

        <yandex-map-default-marker :settings="current" />


      </yandex-map>

    </div>
    <div>
      <div class="py-1 px-4" v-for="p, i in INC_POINT" :key="i">
        <div
          @click="update(p); LOCATION.center = p.coordinates; LOCATION.zoom = 17; enabledBehaviors = []"
          class="text-left h-10 border border-gray-400 p-2 rounded-md cursor-pointer
      ">{{ p.title }}
          <span class="float-right font-bold">{{ last[p.id] }} °C</span></div>
      </div>

      <h2 class="text-4xl">{{ current.title }}</h2>
      <Chart :constructorType="'stockChart'" class="hc" :options="chartOptions"
        ref="chart">
      </Chart>



      <div class="hidden">
        {{ point }}

        {{ markers }}
      </div>
    </div>


  </div>





</template>

<script setup>

import { ref } from 'vue'
import {
  YandexMap,
  YandexMapDefaultSchemeLayer,
  YandexMapDefaultFeaturesLayer,
  YandexMapDefaultMarker
} from 'vue-yandex-maps';

const INC_POINT = [
  {
    id: "28-0547d446e64e",
    coordinates: [37.696355, 54.213893],
    title: "улица Бондаренко, 39",
  },
  {
    id: "28e3ae45d4e728e3",
    coordinates: [37.696355, 54.213893],
    title: "коридор",
  },
  {
    id: "2887f246d4e152ec",
    coordinates: [37.696355, 54.213893],
    title: "палата",
  },
];

const current = ref()
const last = ref()
update(INC_POINT[0])


const LOCATION = ref({
  center: [37.617348, 54.193122], // starting position [lng, lat]
  zoom: 13, // starting zoom
});

const enabledBehaviors = ref(['drag', 'scrollZoom', 'pinchZoom', 'dblClick']);

function update(obj) {
  current.value = obj
  fetch("https://monitoring.megalend.ru/api/index.php?id="+current.value.id)
    .then(response => response.json())
    .then(data => {

      chartOptions.value.series[0].data = data.data

      lastValue.value = data.data.slice(-1)[0][1]

      // Chart.update()

    })
}

fetch("https://monitoring.megalend.ru/api/last.php")
  .then(response => response.json())
  .then(data => {
    last.value = data.data


  })



import { Chart } from 'highcharts-vue';


const chartOptions = ref({
  title: {
    // text: "улица Бондаренко, 39"
  },
  subtitle: {
    // text: 'AstraSmart | тестовый датчик'
  },

  series: [{
    name: 'Температура',
    // type: 'area',
    data: [[0, 0]],
    showInNavigator: true,
    tooltip: {
      valueDecimals: 2
    }
  }],
  rangeSelector: {
    buttons: [{
      type: 'hour',
      count: 1,
      text: '1 час'
    }, {
      type: 'day',
      count: 1,
      text: '1 день'
    }, {
      type: 'week',
      count: 1,
      text: '1 нед'
    }, {
      type: 'all',
      count: 1,
      text: 'All'
    }],
    selected: 4,
    inputEnabled: true
  },
}
)

// 54.213893, 37.696355

const lastValue = ref(0)

// const lastValue = computed(() => {
//   let arr = chartOptions.value.series[0].data
//   return arr.slice(-1)[0][1]
// })
// import stockInit from 'highcharts/modules/stock'

</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  position: absolute;
  height: 100%;
  width: 100%;
}
</style>
