import { createApp } from 'vue'
import App from './App.vue'
import { createYmaps } from 'vue-yandex-maps';

import Highcharts from "highcharts";
import HighchartsVue from 'highcharts-vue';

import Stock from "highcharts/modules/stock";
Stock(Highcharts);

import './style.css'

const app = createApp(App);

app.use(createYmaps({
  apikey: "17c50784-0789-4b34-a62b-d8d613f65595",
  // version: '2.1'
}));

app.use(HighchartsVue, { tagName: 'charts' })

app.mount('#app');
